@use 'sass:math';
@import '../../../styles/variables';

.visat-TimelineControlButton {
  position: absolute;
  top: 0;
  left: .5rem;
  background: var(--base10);
  width: 2rem;
  height: 1.25rem;
  border-radius: 1rem 1rem 0 0;
  margin-top: -1.25rem;
  display: flex;
  justify-content: center;
  padding-top: .1rem;
  color: $baseIconColor;
  cursor: pointer;
  border: 1px solid var(--base25);
  border-bottom-width: 0;
}

.visat-TimelineControlButton:hover {
  background: var(--base10);
}
.visat-TimelineControlButton:hover:after {
  content: "";
  border-radius: 1rem 1rem 0 0;
  @include positionFill();
  background: $hoverBackgroundColor;
}

.visat-TimelineControlButton.is-collapsed > .ptr-react-icon {
  transform: rotate(180deg);
}
