@use 'sass:math';
@import '../../../styles/variables';

$mapBorder: 0.05rem solid var(--base30); // the width in rem fixes the ugly white space between border and content in Chrome

.visat-Map {
  flex: 1;
  background-image:  linear-gradient(var(--base20) 1px, transparent 1px), linear-gradient(to right, var(--base20) 1px, transparent 1px);
  background-size: 20px 20px;
  position: relative;
}

.visat-Map .ptr-map-set {
  height: 100%;
}

.visat-Map .ptr-map-grid .ptr-map-grid-cell.row1 .ptr-map {
  border-top: 0;
}

.visat-Map .ptr-map-grid .ptr-map-grid-cell.col1 .ptr-map {
  border-left: 0;
}

.visat-Map .ptr-map-grid .ptr-map-grid-cell:not(.row1) .ptr-map {
  border-top: $mapBorder;
}

.visat-Map .ptr-map-grid .ptr-map-grid-cell:not(.col1) .ptr-map {
  border-left: $mapBorder;
}

// Map slider
.visat-MapCompareSlider {
  height: 100%;
}

.visat-MapCompareSlider .ptr-map-controls-wrapper {
  z-index: auto;
}

div[data-rcs="handle-container"] {
  z-index: 3;
}

div[data-rcs="clip-item"] {
  z-index: 2;
}

// Map set info
.visat-MapSetInfo {
  position: absolute;
  left: 3.5rem;
  bottom: .3rem;
  display: flex;
  align-items: center;
  grid-gap: .5rem;
}

.visat-MapSetInfo > .ptr-MapScale {
  position: relative;
  left: auto;
  bottom: auto;
  z-index: 1;
}

.visat-MapSetInfo > .ptr-MapScale .ptr-MapScale-content {
  border: 1px solid var(--base50);
  border-top-width: 0;
  background: none;
}


.visat-MapSetInfo > .ptr-MapScale .ptr-MapScale-content span {
  font-size: $a0;
  color: var(--base50);
}

// Map set controls
.visat-MapSetControls {
  position: absolute;
  right: .7rem;
  bottom: .7rem;
  grid-gap: .5rem;
  display: flex;
  flex-direction: column;
  z-index: 9;
}

.visat-MapSetControls > .ptr-map-controls {
  position: relative;
  bottom: auto;
  right: auto;
}

// Zoom controls
.visat-MapSetControls .zoom-control.control {
  background: var(--base05);
  box-shadow: $floatingElementShadow;
}

.ptr-dark .visat-MapSetControls .zoom-control.control {
  border: .05rem solid var(--base30);
}

.visat-MapSetControls .zoom-control.control .ptr-button {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: none;
  background: var(--base05);
  width: $iconToolSize_medium;
  height: $iconToolSize_medium;
}

.visat-MapSetControls .zoom-control.control {
  border-radius: math.div($iconToolSize_medium,2);
}

.visat-MapSetControls .zoom-control.control .ptr-button:hover {
  background: $hoverBackgroundColor;
}

.ptr-dark .visat-MapSetControls .zoom-control.control .ptr-button:hover {
  background: $hoverBackgroundColor-dark;
}

.visat-MapSetControls .zoom-control.control .ptr-button:first-child,
.visat-MapSetControls .zoom-control.control .ptr-button:first-child:hover {
  border-radius: math.div($iconToolSize_medium,2) math.div($iconToolSize_medium,2) 0 0;
}

.visat-MapSetControls .zoom-control.control .ptr-button:last-child,
.visat-MapSetControls .zoom-control.control .ptr-button:last-child:hover {
  border-radius: 0 0 math.div($iconToolSize_medium,2) math.div($iconToolSize_medium,2);
}

.visat-MapSetControls .zoom-control.control .ptr-button > .ptr-icon {
  width: $iconSize;
  height: $iconSize;
  color: $secondaryIconColor;
}

.visat-MapSetControls .zoom-control.control .ptr-button:first-child > .ptr-icon {
  margin-top: .5rem;
}

.visat-MapSetControls .zoom-control.control .ptr-button:last-child > .ptr-icon {
  margin-bottom: .5rem;
}

.visat-MapSetControls .zoom-control.control .ptr-button:hover > .ptr-icon {
  color: $baseIconColor;
}