@import '~@gisatcz/ptr-core/lib/styles/_variables';
@import '~@gisatcz/visat-components/lib/styles/_variables';

@mixin frozenGlassEffect {
	backdrop-filter: blur(6px) saturate(180%);
	-webkit-backdrop-filter: blur(6px) saturate(180%);
}

$textColor_base: var(--accent80);

// Floating elements
$floatingBoxBackground: rgba(var(--base0rgb), 0.85);
$floatingBoxShadow: 0 5px 10px rgba(0, 0, 0, 0.15);
$floatingElementShadow: 0 2px 5px rgba(0, 0, 0, 0.35);
