@use 'sass:math';
@import '../../styles/variables';

// Animations
$elementOpeningTransitionDuration: 400ms;

$timelineHeight: 10rem;
$timelineHeight_small: 8rem;
$textColor_base: var(--accent80);
$iconColor_base: var(--accent80);

.visat-Timeline {
	background: var(--base10);
	position: relative;
	bottom: 0;
	width: 100%;
	z-index: 4;

	border-top: 1px solid var(--base20);
	transition: height $elementOpeningTransitionDuration ease;
	@include maxWidth('medium') {
		height: $timelineHeight_small;
	}

	.ptr-timeline-content {
		background: none;
	}

	.ptr-maptimeline-scrollable {
		overflow-y: auto;
		overflow-x: hidden;
		margin-bottom: 7px;
		@include maxWidth('medium') {
			height: calc($timelineHeight_small - 2rem);
		}
	}

	.ptr-timeline-legend-placeholder {
		flex: 0 1 auto;
		display: flex;
		flex-flow: column;
		min-width: 20rem;
		@include maxWidth('medium') {
			min-width: 15rem;
		}
		@include maxWidth('x-small') {
			min-width: 10rem;
		}
	}

	.ptr-maptimeline {
		display: flex;

		.ptr-popup {
			@include frozenGlassEffect();
			background-color: rgba(17, 25, 40, 0.75);
			border-radius: 0.25rem;
			border: 1px solid rgba(255, 255, 255, 0.125);

			color: var(--base30);

			.ptr-timeline-tooltip-layer {
				color: var(--base30);
			}

			.ptr-timeline-tooltip-layer > div:nth-child(2) {
				color: var(--base50);

				em {
					color: var(--base30);
				}
			}
		}

		.ptr-timeline {
			display: flex;
			overflow: hidden;
			flex: 1 1 auto;
		}
	}

	.ptr-maptimelinelegend {
		flex: 0 1 auto;
		display: flex;
		flex-flow: column;
		font-size: $b1;
		min-width: 20rem;
		width: 20rem;
		@include maxWidth('medium') {
			min-width: 15rem;
			width: 15rem;
		}
		@include maxWidth('x-small') {
			min-width: 10rem;
			width: 10rem;
		}
	}

	.ptr-maptimeline-legenditem {
		display: block;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		padding: 0.05rem 0.5rem 0 1rem;
		color: $textColor_base;
		font-size: $b1;
		font-weight: bold;
		@include maxWidth('medium') {
			font-size: $a0;
			padding: 0.05rem 0.5rem 0 0.5rem;
		}
	}

	.ptr-maptimeline-legenditem:first-letter {
		text-transform: uppercase;
	}

	.ptr-maptimeline-legenditem > em {
		color: var(--base10);
		font-size: $b1;
	}

	g.ptr-timeline-year,
	.ptr-timeline-year > line {
		stroke: var(--accent80);
		// fill: var(--accent05);
	}

	g.ptr-timeline-month,
	g.ptr-timeline-month > line,
	g.ptr-timeline-day,
	g.ptr-timeline-hour,
	g.ptr-timeline-hour > line,
	g.ptr-timeline-minute,
	g.ptr-timeline-minute > line {
		stroke: $iconColor_base;
		fill: none;
	}

	g.ptr-timeline-year,
	.ptr-timeline-year > line {
		stroke-width: 2px;
		stroke: var(--accent70);
	}

	g.ptr-timeline-month.secondary > line {
		stroke: var(--accent70);
	}

	.ptr-timeline-year-label,
	.ptr-timeline-month-label {
		stroke: none;
		fill: $textColor_base;
		font-size: 0.85rem;
	}

	.ptr-timeline-month-label {
		font-size: $a0;
	}

	.ptr-timeline-year-label {
		font-weight: bold;
	}

	.ptr-timeline-tooltip-time {
		white-space: nowrap;

		:first-child {
			margin-left: m(1);
		}
	}

	.ptr-timeline-tooltip-layer {
		white-space: nowrap;
		margin: 2px 0;
		display: flex;
		width: 100%;
	}
	.ptr-timeline-tooltip-layer > div {
		flex-grow: 1;
	}
	.ptr-timeline-tooltip-layer > div:first-child {
		margin-right: m(math.div(1, 3));
		position: relative;
		width: m(math.div(2, 3));
		flex-grow: 0;
	}

	.ptr-timeline-tooltip-layer span.dot {
		height: 10px;
		width: 10px;
		background-color: #bbb;
		border-radius: 50%;
		display: inline-block;
	}
	.ptr-timeline-tooltip-layer > div:nth-child(2) {
		display: flex;
		flex-flow: column;
		font-size: $a0;
		color: var(--base60);
		em {
			color: var(--base100);
			font-size: $b1;
		}
	}

	.ptr-timeline-overlay rect {
		cursor: pointer;
	}

	.ptr-timeline-overlay text {
		cursor: pointer;
	}
}

.visat-Timeline.is-collapsed {
	height: 0;
}
