@use 'sass:math';
@import '../../../../styles/variables';

.visat-MapWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.visat-MapWrapper:after {
  content: "";
  border: .15rem dashed transparent;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.visat-MapWrapper.one-map.active:after {
  border: 0;
}

.visat-MapWrapper.active:after {
  border: .15rem dashed var(--accent50);
  z-index: 1;
}

// Map Tools
.visat-MapTools {
  position: absolute;
  top: .7rem;
  right: .7rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  grid-gap: .5rem;
}

.visat-MapTools.is-left {
  left: .7rem;
  right: auto;
}

// Layers control
.visat-MapTools > .ptr-simple-layers-control {
  position: relative;
  bottom: auto !important;
  right: auto !important;
  margin-bottom: 0;
}

.visat-MapTools .ptr-simple-layers-control {
  width: $iconToolSize_medium;
  height: $iconToolSize_medium;
  border-radius: math.div($iconToolSize_medium,2);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--base05);
  box-shadow: $floatingElementShadow;
}

.ptr-dark .visat-MapTools .ptr-simple-layers-control {
  border: .05rem solid var(--base30);
}

.visat-MapTools .ptr-simple-layers-control-menu {
  filter: drop-shadow(0 3px 3px rgba(0, 0, 0, .15));
  background: var(--base05);
}

.visat-MapTools .ptr-simple-layers-control-menu.left:after {
  border-left-color: var(--base05);
}

.visat-MapTools .ptr-simple-layers-control.control > .ptr-button {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: none;
  border-radius: math.div($iconToolSize_medium,2);
  background: var(--base05);
}

.visat-MapTools .ptr-simple-layers-control.control > .ptr-button:hover {
  border-radius: math.div($iconToolSize_medium,2);
  background: $hoverBackgroundColor;
}

.ptr-dark .visat-MapTools .ptr-simple-layers-control .ptr-button:hover {
  background: $hoverBackgroundColor-dark;
}

.visat-MapTools .ptr-simple-layers-control .ptr-button > .ptr-icon {
  width: $iconSize;
  height: $iconSize;
  color: $secondaryIconColor;
}

.visat-MapTools .ptr-simple-layers-control .ptr-button:hover > .ptr-icon {
  color: $baseIconColor;
}

.visat-MapTools .ptr-simple-layers-control.open .ptr-button > .ptr-icon {
  color: $accentedIconColor;
}

// Layer labels
.visat-LayerLabelsContainer {
  position: absolute;
  top: .7rem;
  left: .7rem;
  z-index: 2;
}

.visat-LayerLabelsContainer.has-offset {
  left: 3.5rem;
}

.visat-LayerLabelsContainer.is-right {
  left: auto;
  right: .7rem;
}

.visat-LayerLabelsContainer.is-right.has-offset {
  right: 3.5rem;
}