@use 'sass:math';
@import '../../../../../styles/variables';

.visat-AppList {
  width: 100%;
  padding-bottom: m(3);
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: m(3);

  @include maxWidth('x-large') {
    grid-gap: m(3);
  }

  @include maxWidth('small') {
    align-items: flex-start;
  }
}