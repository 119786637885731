@use 'sass:math';
@import '../variables';

.visat-IndexHeader {
  height: $headerHeight;
  width: 100%;
  border-bottom: 1px dotted var(--base25);

  @include maxWidth('x-small') {
    height: $headerHeight_x-small;
  }
}

.visat-IndexHeader-content {
  width: 100%;
  height: 100%;
  padding: 0 $indexScreenHorizontalPadding;
  display: flex;

  @include maxWidth('medium') {
    padding: 0 1rem;
  }
}

.visat-IndexTitle {
  display: flex;
  font-family: "Fredoka" ,"OpenSans", sans-serif;
  font-weight: 500;
  align-items: center;
  margin: 0;
  padding: 0;
  color: $accentedTextColor;
  font-size: $b3;

  @include maxWidth('small') {
    font-size: $a2;
  }
}