@use 'sass:math';
@import '../../variables';

.visat-IndexNav {
  display: flex;
  justify-content: center;
  flex: 1;
  margin: 0 m(1);
  grid-gap: .5rem;

  @include maxWidth('medium') {
    margin: 0 1rem;
    grid-gap: .5rem;
  }

  @include maxWidth('small') {
    margin: 0 1rem;
    grid-gap: .25rem;
  }
}

/* Item */
.visat-IndexNavItem {
  text-decoration: none;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .5rem 1.5rem 0;
  border-bottom: .15rem solid transparent;
  color: $baseIconColor;
  
   svg {
    color: var(--base85);
  }

  @include maxWidth('medium') {
    padding: .5rem 1rem 0;
  }

  @include maxWidth('small') {
    flex-direction: column;
    padding: .65rem 1rem 0;
  }

  @include maxWidth('x-small') {
    flex-direction: row;
    padding: .35rem .75rem 0;
  }
}

.visat-IndexNavItem > .ptr-icon,
.visat-IndexNavItem > .ptr-react-icon {
  margin-right: .5rem;
  width: $iconSize;
  height: $iconSize;

  @include maxWidth('medium') {
    margin-right: .35rem;
  }

  @include maxWidth('small') {
    margin: 0 0 .25rem;
  }

  @include maxWidth('x-small') {
    margin: 0;
  }
}

.visat-IndexNavItem > .ptr-icon .line {
  fill: $baseIconColor;
}

.visat-IndexNavItem-title {
  font-size: $a1;
  letter-spacing: .5px;
  font-weight: 500;
  line-height: 1.05;
  color: $baseTextColor;
  padding-top: .1rem;

  @include maxWidth('small') {
    font-size: $b1;
    padding-top: 0;
  }

  @include maxWidth('x-small') {
    display: none;
  }
}

// hover
.visat-IndexNavItem:hover {
  border-bottom-color: $baseBorderColor;
  background: $hoverBackgroundColor;
}

.ptr-dark .visat-IndexNavItem:not(.is-active):hover {
  background: $hoverBackgroundColor-dark;
}

// active
.visat-IndexNavItem.is-active {
  background: $accentedBackgroundLightColor;
  border-bottom-color: $accentedBorderColor;
  color: $accentedIconColor;
  font-weight: 600;

  svg {
    color: $accentedIconColor;
  }

}

.visat-IndexNavItem.is-active .visat-IndexNavItem-title {
  color: $accentedTextColor;
  font-weight: 500;
}

// disabled
.visat-IndexNavItem.is-disabled {
  pointer-events: none;
  color: $disabledIconColor;
}

.visat-IndexNavItem.is-disabled > .visat-IndexNavItem-title {
  color: $disabledTextColor;
}

