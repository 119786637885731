@use 'sass:math';
@import '../../styles/variables';

.visat-App {
  @include positionFill();
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  background: var(--base05);
  background: linear-gradient(rgb(253, 252, 250), rgb(245, 244, 242) 50%);
}

.visat-App.ptr-dark {
  background: var(--base05);
  background: linear-gradient(rgb(33, 32, 30), rgb(27, 26, 24));
}