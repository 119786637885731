@use 'sass:math';
@import '../../styles/variables';

.visat-ProductViewerScreen {
  @include positionFill();
  display: flex;
  flex-flow: column;
  background: var(--base05);
  background: linear-gradient(var(--base05), var(--base10) 50%);
  width: 100%;
  height: 100%;
}


.visat-ProductViewerScreen-Content{
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}