@use 'sass:math';
@import '../../../../styles/variables';

.visat-CatalogFilter {
  width: 20rem;
  min-width: 20rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  //background: aliceblue;

  @include maxWidth('medium') {
    width: 100%;
    margin-bottom: m(math.div(2,3));
    grid-gap: .75rem;
  }
}

.visat-CatalogFilterPrimaryItems {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include maxWidth('medium') {
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: m(math.div(2,3));
    margin-left: -.5rem;
  }
}

.visat-Tag {
  color: $baseTextColor;
}

