@use 'sass:math';
@import '../../../variables';
@import '~@gisatcz/visat-components/lib/styles/_placeholderAnimation';

.visat-CatalogFilterCategorySection {
	padding-left: math.div($indexScreenHorizontalPadding, 2);

	@include maxWidth('medium') {
		padding-left: 0;
	}
}

.visat-CatalogFilterCategorySection-title {
	position: relative;
	overflow: hidden;
	font-size: $b1;
	font-weight: 500;
	letter-spacing: 0.5px;
	color: $secondaryTextColor;
	text-transform: uppercase;
}

.visat-CatalogFilterCategorySection-title.is-placeholder {
	background: $placeholderLightColor;
	width: 3rem;
	height: 0.7rem;
	border-radius: 0.5rem;
	margin-bottom: 0.5rem;
}

.visat-CatalogFilterCategorySection-title.is-placeholder::before {
	@include placeholderAnimation();
}

.visat-CatalogFilterCategorySection-body {
	margin-top: 0.2rem;
}
