@use 'sass:math';
@import '../../../styles/variables';
@import '~@gisatcz/visat-components/lib/styles/_placeholderAnimation';

.ptr-ProductCard {
	min-height: 8rem;
	border-radius: 0.25rem;
	background: var(--base0);
	display: flex;
	flex-direction: column;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
	padding: 1.65rem m(1) m(1);
	grid-gap: m(math.div(2, 3));
}

.ptr-ProductCardHeader {
}

.ptr-ProductCardBody {
	flex: 1;
}

.ptr-ProductCardFooter {
	display: flex;
}

.ptr-ProductCardTitle {
	position: relative;
	overflow: hidden;
	margin: 0 0 0.5rem;
	font-size: $b2;
	color: $baseTextColor;
}

.ptr-ProductCardTitle a {
	color: $baseTextColor;
	font-weight: 600;
	text-decoration: none;
	background-image: linear-gradient(var(--accent30), var(--accent30));
	background-size: 100% 0.2rem;
	background-repeat: no-repeat;
	background-position: left bottom;
}

.ptr-ProductCardTitle a:hover {
	color: var(--base100);
	background-image: linear-gradient(var(--accent50), var(--accent50));
}

.ptr-ProductCardTitle.is-placeholder {
	background: $placeholderColor;
	width: 10rem;
	height: 1rem;
	border-radius: 0.5rem;
}

.ptr-ProductCardTitle.is-placeholder::before {
	@include placeholderAnimation();
}

.ptr-ProductCardDescription {
	position: relative;
	overflow: hidden;
	margin: 0;
	font-size: $b1;
	color: $secondaryTextColor;
}

.ptr-ProductCardDescription.is-large {
	font-size: $a1;
	max-width: 40rem;
}

.ptr-ProductCardDescription.is-placeholder {
	background: $placeholderLightColor;
	height: 0.7rem;
	border-radius: 0.5rem;
	margin-bottom: 0.35rem;
}

.ptr-ProductCardDescription.is-placeholder::before {
	@include placeholderAnimation();
}

.ptr-ProductCardTools {
	display: flex;
	flex: 1;
	grid-gap: m(1);
	color: $secondaryIconColor;
	margin: -0.5rem;
}

.ptr-ProductCardTools-left {
	flex: 1;
	display: flex;
	justify-content: flex-start;
}

.ptr-ProductCardTools-right {
	display: flex;
	align-items: center;
}
