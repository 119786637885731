@use 'sass:math';
@import '../../../../../../styles/variables';

.visat-CollectionCard {
  position: relative;
  z-index: 2;
  box-shadow: 10px 20px 20px rgba(0,0,0, .05);
}

.visat-CollectionCard:before {
  content: " ";
  width: 100%;
  height: 100%;
  background: var(--base0);
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  border-radius: .25rem;
  box-shadow: 0 3px 10px rgba(0,0,0, .08);
}

.visat-CollectionCard:after {
  content: " ";
  width: 100%;
  height: 100%;
  background: var(--base05);
  position: absolute;
  z-index: -1;
  left: 10px;
  top: 10px;
  border-radius: .25rem;
}

.ptr-dark .visat-CollectionCard:after {
  background: var(--base20);
}

.visat-CollectionCardDescription {
  color: $baseTextColor;
}

.visat-CollectionCardIconTool {
  color: $baseTextColor;
}

.visat-CollectionCardIconTool.is-disabled {
  color: var(--base30);
}