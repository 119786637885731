@use 'sass:math';
@import '../../../../../styles/variables';
@import '~@gisatcz/visat-components/lib/styles/_placeholderAnimation';

.visat-CatalogFilterPrimaryItem {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0.1rem 1rem;

	@include maxWidth('medium') {
		padding: 0.1rem 0.5rem;
	}
}

.visat-CatalogFilterPrimaryItem-title {
	display: flex;
	align-items: center;
	width: 12rem;
	color: $secondaryIconColor;

	@include maxWidth('medium') {
		width: auto;
	}
}

.visat-CatalogFilterPrimaryItem-title > .ptr-react-icon {
	margin-right: 0.35rem;
	width: $smallIconSize;
	height: $smallIconSize;
}

.visat-CatalogFilterPrimaryItem-name {
	color: $baseTextColor;
	padding-top: 0.15rem;
}

.visat-CatalogFilterPrimaryItem-toggle {
	margin-left: 0.5rem;
	display: flex;
	flex-direction: column;
	grid-gap: 0.3rem;
}

// interactive
.visat-CatalogFilterPrimaryItem.is-interactive {
	cursor: pointer;
}
.visat-CatalogFilterPrimaryItem.is-interactive:hover {
	background: $hoverBackgroundColor;
}

// active
.visat-CatalogFilterPrimaryItem.is-active .visat-CatalogFilterPrimaryItem-title {
	color: $accentedTextColor;
}

.visat-CatalogFilterPrimaryItem.is-active .visat-CatalogFilterPrimaryItem-name {
	color: $accentedTextColor;
	font-weight: 500;
}

// disabled
.visat-CatalogFilterPrimaryItem.is-disabled {
	pointer-events: none;
}

.visat-CatalogFilterPrimaryItem.is-disabled .visat-CatalogFilterPrimaryItem-title {
	color: $disabledIconColor;
}

.visat-CatalogFilterPrimaryItem.is-disabled .visat-CatalogFilterPrimaryItem-name {
	color: $disabledTextColor;
}

// placeholder
.visat-CatalogFilterPrimaryItem.is-placeholder {
	position: relative;
	overflow: hidden;
	background-color: $placeholderLightColor;
	width: 12rem;
	height: 1rem;
	border-radius: 0.5rem;
	margin: 0.25rem 1rem;
}

.visat-CatalogFilterPrimaryItem.is-placeholder::before {
	@include placeholderAnimation();
}
