@use 'sass:math';
@import '../variables';

.visat-IndexBody {
  height: calc(100% - #{$headerHeight});
  overflow-y: auto;

  @include maxWidth('x-small') {
    height: calc(100% - #{$headerHeight_x-small});
  }
}

.visat-IndexBody-content {
  padding: m(2) $indexScreenHorizontalPadding m(2) math.div($indexScreenHorizontalPadding,2);
  height: 100%;

  @include maxWidth('medium') {
    padding: m(math.div(4,3)) $indexScreenHorizontalPadding_medium;
  }
}

