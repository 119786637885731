@import '../../../../styles/variables';

.visat-LayerLabel {
  background: var(--base05);
}

// Header
.visat-LayerLabelHeader {
  display: flex;
  align-items: center;
  font-size: $b1;
  height: 100%;
  min-width: 7rem;
}

.visat-LayerLabelHeader-title {
  display: flex;
  align-items: center;
  color: $baseTextColor;
  font-weight: 600;
  padding: .1rem 1rem 0 .5rem;
}

// Content
.visat-LayerLabelBody {
  display: flex;
  flex-direction: column;
  background: var(--base05);
}

.visat-LayerLabelBodyItem {
  display: flex;
  height: 2rem;
  font-size: $b1;
  align-items: center;
  padding: 0 .5rem;
  color: $secondaryIconColor;
  border-top: 1px solid var(--base25);
  text-decoration: none;
}

.visat-LayerLabelBodyItem:first-child {
  border-top: 0;
}

.visat-LayerLabelBodyItem.is-hoverable {
  cursor: pointer;
}

.visat-LayerLabelBodyItem.is-hoverable:hover,
a.visat-LayerLabelBodyItem.is-hoverable:hover {
  background: $hoverBackgroundColor;
  color: $baseTextColor;
}

.visat-LayerLabelBodyItem.is-dangerous {
  color: #c12123;
}

.visat-LayerLabelBodyItem.is-dangerous:hover {
  background: #f1d7d8;
}

.visat-LayerLabelBodyItem.is-disabled {
  color: $disabledTextColor;
  pointer-events: none;
}

.visat-LayerLabelBodyItem-title {
  flex: 1;
  white-space: nowrap;
  color: $secondaryTextColor;
}

.visat-LayerLabelBodyItem.is-hoverable:hover .visat-LayerLabelBodyItem-title {
  color: $baseTextColor;
}

.visat-LayerLabelBodyItem-tool {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 2rem;
}