@use 'sass:math';
@import '../../../../styles/variables';

.visat-DatasetCatalog {
  display: flex;
  grid-gap: m(2);
  height: 100%;
  align-items: flex-start;
  //background: antiquewhite;

  @include maxWidth('xx-large') {
    grid-gap: m(math.div(4,3));
  }

  @include maxWidth('medium') {
    flex-direction: column;
    justify-content: flex-start;
  }
}