@use 'sass:math';
@import '../../../../styles/variables';

.visat-IndexTools {
  display: flex;
  height: 100%;
  padding: .75rem 0 .5rem;

  @include maxWidth('x-small') {
    padding: .35rem 0;
  }
}