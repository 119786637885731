@use 'sass:math';
@import '../../../../styles/variables';

.visat-GeonamesSearchBox {
  position: absolute;
  top: 0;
  right: 3rem;
}

.visat-GeonamesSearchBox.ptr-GeonamesSearch > div > .wrapper {
  box-shadow: $floatingElementShadow;
}
