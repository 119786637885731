@use 'sass:math';
@import '../../../../styles/variables';

.visat-IndexCenteredWrap {
  max-width: 100rem;
  height: 100%;
  margin: 0 auto;

  //@include minWidth('xx-large') {
  //  max-width: 100rem;
  //}
}