@use 'sass:math';
@import '../../../styles/variables';

.visat-ProductDetails {
  padding: m(math.div(4,3));
}

.visat-ProductDetails-body {
  margin: m(1) 0 m(2);
}

.visat-ProductDetails-footer {
  display: flex;
  grid-gap: .5rem;
  width: 100%;
  justify-content: space-between;

  > a > div {
    height: 100%;
  }

}

