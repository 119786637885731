@use 'sass:math';
@import '../../../../../styles/variables';

$gridGap: m(math.div(4,3));

.visat-CollectionList {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;

  @include maxWidth('x-small') {
    align-items: center;
  }
}

.visat-CollectionList-header {
  display: flex;
  margin: 0 0 m(math.div(1,3)) 0;
  font-size: $b1;
  color: $secondaryTextColor;
  width: 100%;
  grid-gap: m(1);
}

.visat-CollectionList-count {
  flex: 1;
  font-weight: 500;
}

.visat-CollectionList-orders {
  display: flex;
  align-items: center;
  color: $disabledIconColor;
}

.visat-CollectionList-orders > div:first-child {
  font-weight: 500;
  margin-right: .25rem;
  color: $disabledIconColor;
}

.visat-CollectionList-body {
  color: $baseTextColor;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: $gridGap;
  padding-bottom: m(2);
  //background: darkorange;

  @include maxWidth('x-large') {
    grid-template-columns: 1fr 1fr;
  }

  @include maxWidth('medium') {
    justify-content: flex-start;
    align-items: flex-start;
  }

  @include maxWidth('small') {
    grid-gap: m(1);
  }

  @include maxWidth('x-small') {
    grid-template-columns: 1fr;
  }
}

.visat-CollectionList-body.nothing-filtered {
  display: block;
  width: 100%;
  height: 100%;
}

.visat-CollectionList .visat-CollectionCard {
  max-width: 27rem;
  min-width: 15rem;
  height: 100%;
}