@use 'sass:math';
@import '../../../styles/variables';

.visat-ProductViewerHeader {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  background: var(--base05);
  border-bottom: 1px solid var(--base25);
  width: 100%;
  height: $headerHeight;
  padding: 0 .5rem 0 1rem;
}

.visat-ProductViewerTitle {
  margin: 0;
  display: flex;
  font-size: 1.75rem;
  align-items: center;
  padding-top: .15rem;
}

.visat-ProductViewerTitle > h1 {
  font-family: "Fredoka" ,"OpenSans", sans-serif;
  color: $accentedTextColor;
  font-size: 1.75rem;
  font-weight: 500;
  margin: -.35rem .15rem 0 0;

  > a {
    text-decoration: none;
    color: $accentedTextColor;
  }
}

.visat-ProductViewerTitle-divider {
  margin: 0 .5rem;
  padding-top: .1rem;
  font-size: $b2;
  font-weight: 300;
  color: var(--base40);
}

.visat-ProductViewerTitle-type > a {
  display: flex;
  grid-gap: .35rem;
  align-items: center;
  color: $baseTextColor;
  padding-top: .35rem;
  text-decoration: none;


  > a {
  }
}

.visat-ProductViewerTitle-type > a > .ptr-react-icon {
  margin-top: -.15rem;
}

.visat-ProductViewerTitle-type > a > span {
  font-weight: 500;
}

.visat-ProductViewerTitle-type > a > span:first-letter {
  text-transform: uppercase;
}

.visat-ProductViewerProductTitle {
  margin: 0 .5rem 0 0;
  padding-top: .15rem;
  color: $baseTextColor;
  font-size: $b2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Product tool
.visat-ProductViewerProductTool {
  margin-top: .25rem;
}

.visat-AddDatasetModal {
  width: 90%;
  height: 90%;
  max-width: 90%;
  max-height: 90%;
}

.visat-AddDatasetModal-title {
  margin: m(1) m(1) m(2);
}

.visat-AddDatasetModal-content {
  padding: 0 m(1) m(1) m(math.div(1,3));
}

// App tools
.visat-ProductViewerTools {
  display: flex;
  padding-top: .25rem;
}

.visat-ProductViewerTools-group {
  display: flex;
  padding: 0 .5rem;
  position: relative;
}

.visat-ProductViewerTools-group:not(:last-child):after {
  content: "";
  position: absolute;
  right: 0;
  top: calc((#{$iconToolSize} - #{$iconSize})/2);
  width: 1px;
  height: $iconSize;
  background: var(--base25);
}
